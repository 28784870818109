<template>
  <v-form @submit.prevent="handleSubmit">
    <v-card-title>{{ $t("common.forgotPassword") }}</v-card-title>
    <v-card-text>
      <v-text-field
        :label="$t('common.email')"
        name="email"
        prepend-icon="person"
        type="text"
        v-model="email"
      ></v-text-field>
    </v-card-text>

    <v-card-actions>
      <div class="flex-grow-1"></div>
      <v-btn color="primary" type="submit">{{ $t("common.send") }}</v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ForgotPassword",
  data() {
    return {
      email: "",
      submitted: false
    };
  },
  computed: {},

  methods: {
    ...mapActions("users", ["forgotPassword"]),

    async handleSubmit(e) {
      e.preventDefault();

      this.submitted = true;
      const { email } = this;
      if (email) {
        await this.forgotPassword(email);

        this.$router.push("/login");
      }
    }
  }
};
</script>
